import ptDict from './i18n/pt.json';
import enDict from './i18n/en.json';

export class TranslationProvider {

   private catalog = {
      pt: ptDict,
      en: enDict,
   }

   constructor(private currentLanguage: string) { }

   changeLanguage(key: string) {
      this.currentLanguage = key;
   }

   getText(key: string): string {
      return this.catalog[this.currentLanguage][key];
   }
}

export type MediaChunk = {
   uniqueId: string,
   part: number,
   of: number,
   data: Blob,
   namespace: string
}

export type AckData = {
   uniqueId: string,
   namespace: string,
   ack: boolean
}

export async function sendChunk(window: Window, chunk: MediaChunk) {
   return window.fetch('/injest', {
      method: 'POST',
      body: await makeBody(chunk),
      headers: defaultHeaders(),
   });
}

export async function acknowledge(window: Window, ackData: AckData) {
   return window.fetch('/ack', {
      method: 'PUT',
      body: JSON.stringify(ackData),
      headers: defaultHeaders(),
   });
}

async function blobToBase64(chunk: Blob): Promise<string> {
   return new Promise((resolve, _) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(chunk);
   });
}

async function makeBody(chunk: MediaChunk): Promise<string> {
   return JSON.stringify({
      ...chunk,
      data: await blobToBase64(chunk.data).then(base64 => base64.substring(base64.indexOf(',') + 1))
   });
}

function defaultHeaders(): Headers {
   const headers = new Headers();
   headers.set('Content-Type', 'application/json');
   return headers;
}

import {DashboardConfiguration} from "./sharedTypes"

export enum RecordingPermission {
  GRANTED,
  SERVER_ERROR,
  DENIED
}

export enum CallbackResult {
  RECORDED,
  DENIED
}

export async function getRecordingPermission(window: Window, config: DashboardConfiguration): Promise<RecordingPermission> {
  if (config.debug) {
    return RecordingPermission.GRANTED;
  }

  const permissionUrl = config.permissionUrl;
  const {status} = await fetch(permissionUrl, {method: "POST"});

  if (status >= 400) {
    return RecordingPermission.DENIED;
  } else if (status >= 500) {
    return RecordingPermission.SERVER_ERROR;
  } else {
    return RecordingPermission.GRANTED;
  }
}

export async function invokeCallback(window: Window, config: DashboardConfiguration): Promise<CallbackResult> {

  if (config.debug) {
    return CallbackResult.RECORDED;
  }

  const callbackUrl = config.callbackUrl;
  const {status} = await fetch(callbackUrl, {method: "POST"});

  if (status < 300) {
    return CallbackResult.RECORDED;
  } else {
    return CallbackResult.DENIED;
  }
}

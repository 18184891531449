let searchParams: URLSearchParams;

export function getValue(window: Window, key: string): any {
   return getParams(window).get(key);
}

export function getInteger(window: Window, key: string): number {
   return parseInt(getParams(window).get(key));
}

export function getFloat(window: Window, key: string): number {
   return parseFloat(getParams(window).get(key));
}

export function getString(window: Window, key: string): string {
   return getParams(window).get(key);
}
export function getBoolean(window: Window, key: string): boolean {
   const value = getString(window, key);
   return (/true/i).test(value) || (/1/i).test(value) || (/yes/i).test(value) || (/on/i).test(value);
}

function getParams(window: Window): URLSearchParams {
   if (!searchParams) {
      searchParams = new URLSearchParams(window.location.search)
   }
   return searchParams;
}

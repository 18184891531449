
export default class ProgressBar {

   private wrapperDiv: HTMLDivElement
   private barDiv: HTMLDivElement

   constructor(private appElement: HTMLElement) {
      this.wrapperDiv = appElement.ownerDocument.createElement('div');
      this.wrapperDiv.className = "progress-bar";
      this.barDiv = appElement.ownerDocument.createElement('div');
      this.barDiv.className = "gauge"
      this.wrapperDiv.appendChild(this.barDiv);
   }

   setProgress(current: number) {
      this.barDiv.style.width = `${current}%`;
   }

   hide() {
      this.barDiv.style.opacity= "0";
   }

   show() {
      this.barDiv.style.opacity= "1";
   }

   element(): HTMLElement {
      return this.wrapperDiv;
   }
}

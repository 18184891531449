import Dashboard from "./dashboard";
import {getRecordingPermission, RecordingPermission} from "./listenerService";
import {getInteger, getString, getBoolean} from "./queryString";
import {DashboardConfiguration} from "./sharedTypes";
import {TranslationProvider} from "./translationProvider";
import {getLanguage} from "./util";

export default class App {

  private appElement: HTMLElement;
  private configuration: DashboardConfiguration
  private dashboard: Dashboard;
  private translationProvider: TranslationProvider

  constructor(
    private window: Window,
    private appElementId: string,
  ) {
    this.appElement = this.window.document.getElementById(appElementId);
    this.configuration = {
      recordingTime: getInteger(this.window, "recording_time"),
      uniqueId: getString(this.window, "unique_id"),
      title: getString(this.window, "title"),
      instructions: getString(this.window, "instructions"),
      namespace: getString(this.window, "namespace"),
      callbackUrl: getString(this.window, "callback_url"),
      permissionUrl: getString(this.window, "permission_url"),
      debug: getBoolean(this.window, "debug"),
    };
  }

  async init() {
    this.translationProvider = new TranslationProvider(await getLanguage(window));
    const permission = await getRecordingPermission(this.window, this.configuration);
    switch (permission) {
      case RecordingPermission.DENIED:
        this.appElement.innerHTML = "<h2>Você não pode gravar mais este vídeo</h2>"
        break;
      case RecordingPermission.GRANTED:
        if (this.isSafari()) {
          this.appElement.innerHTML = "<h3>Não é possível rodar esta aplicação no Safari. Por favor, abra em outro navegador.</h3>"
        } else {
          this.buildDashboard();
        }
        break;
      case RecordingPermission.SERVER_ERROR:
        this.appElement.innerHTML = "<h2>Erro no servidor</h2>"
        break;
    }
  }

  buildDashboard() {
    if (this.configuration.recordingTime && this.configuration.uniqueId) {
      this.dashboard = new Dashboard(
        this.window,
        this.configuration,
        this.appElement,
        this.translationProvider
      );
      this.dashboard && this.dashboard.init();
      if (this.configuration.title) {
        window.document.title += ` | ${this.configuration.title}`;
      }
    } else {
      this.appElement.innerHTML = "<h2>Please provide recording_time and unique_id parameter</h2>"
    }
  }

  isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;
  }
}


const defaultApp: App = new App(window, "app");
defaultApp.init();

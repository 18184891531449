import {TranslationProvider} from "./translationProvider";

export enum RecordingActions {
  START_RECORDING, STOP_RECORDING, PREVIEW, SEND, RETURN
}

export type RecordingActionListerner = () => void;

export class RecordingControls {

  private _isRecording: boolean
  private wrapperDiv: HTMLDivElement
  private returnBtn: HTMLButtonElement;
  private returnIcon: HTMLSpanElement;
  private startRecordingBtn: HTMLButtonElement;
  private startRecordingIcon: HTMLSpanElement;
  private stopRecordingBtn: HTMLButtonElement;
  private stopRecordingIcon: HTMLSpanElement;
  private sendRecordingBtn: HTMLButtonElement;
  private sendRecordingIcon: HTMLSpanElement;

  private elements = {}

  constructor(appElement: HTMLElement, translationProvider: TranslationProvider) {

    this.wrapperDiv = appElement.ownerDocument.createElement('div');
    this.wrapperDiv.className = "recording-controls";

    this.returnBtn = appElement.ownerDocument.createElement("button");
    this.returnBtn.className = "control-btn return-btn";
    this.returnBtn.title = translationProvider.getText("close_recording");
    this.returnBtn.disabled = true;
    this.returnIcon = appElement.ownerDocument.createElement("span");
    this.returnIcon.className = "control-btn-icon material-icons";
    this.returnIcon.innerText = "undo";
    this.returnBtn.appendChild(this.returnIcon);


    this.startRecordingBtn = appElement.ownerDocument.createElement("button");
    this.startRecordingBtn.className = "control-btn start-recording-btn";
    this.startRecordingBtn.title = translationProvider.getText("start_recording");
    this.startRecordingIcon = appElement.ownerDocument.createElement("span");
    this.startRecordingIcon.className = "control-btn-icon material-icons";
    this.startRecordingIcon.innerText = "radio_button_checked";
    this.startRecordingBtn.appendChild(this.startRecordingIcon);


    this.stopRecordingBtn = appElement.ownerDocument.createElement("button");
    this.stopRecordingBtn.className = "control-btn stop-recording-btn";
    this.stopRecordingBtn.title = translationProvider.getText("stop_recording");
    this.stopRecordingBtn.disabled = true;
    this.stopRecordingBtn.style.display = "none";
    this.stopRecordingIcon = appElement.ownerDocument.createElement("span");
    this.stopRecordingIcon.className = "control-btn-icon material-icons";
    this.stopRecordingIcon.innerText = "stop";
    this.stopRecordingBtn.appendChild(this.stopRecordingIcon);


    this.sendRecordingBtn = appElement.ownerDocument.createElement("button");
    this.sendRecordingBtn.className = "control-btn send-recording-btn";
    this.sendRecordingBtn.title = translationProvider.getText("send_recording");
    this.sendRecordingBtn.disabled = true;
    this.sendRecordingIcon = appElement.ownerDocument.createElement("span");
    this.sendRecordingIcon.className = "control-btn-icon material-icons";
    this.sendRecordingIcon.innerText = "send";
    this.sendRecordingBtn.appendChild(this.sendRecordingIcon);

    [this.returnBtn,
      this.startRecordingBtn,
      this.stopRecordingBtn,
      this.sendRecordingBtn].forEach(el => this.wrapperDiv.appendChild(el));

    this.elements = {
      [RecordingActions.RETURN]: this.returnBtn,
      [RecordingActions.START_RECORDING]: this.startRecordingBtn,
      [RecordingActions.STOP_RECORDING]: this.stopRecordingBtn,
      [RecordingActions.SEND]: this.sendRecordingBtn
    }
  }

  public get isRecording(): boolean {
    return this._isRecording;
  }

  public set isRecording(value: boolean) {
    this.sendRecordingBtn.disabled = value;
    this.returnBtn.disabled = value;

    this.stopRecordingBtn.disabled = !value;
    this.stopRecordingBtn.style.display = value ? "block" : "none";

    this.startRecordingBtn.disabled = value;
    this.startRecordingBtn.style.display = value ? "none" : "block";

    this._isRecording = value;
  }

  public disableButtons(): void {
    [this.returnBtn, this.startRecordingBtn, this.stopRecordingBtn, this.sendRecordingBtn].forEach(el => el.disabled = true);
  }

  public element(): HTMLElement {
    return this.wrapperDiv;
  }

  public addClickListener(recordingAction: RecordingActions, callback: RecordingActionListerner) {
    this.elements[recordingAction].addEventListener('click', () => {
      callback()
    });
  }
}
